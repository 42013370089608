import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { GridBox, Spinner, Text, Title } from "@thedgbrt/logology-ui-components"
import SEO from "../../seo"
import { getUriParameterByName } from "../../../helpers"

// setup all amplify modules for api to work
import Amplify from "@aws-amplify/core"
import awsconfig from "../../../aws-exports"
import API from "@aws-amplify/api"
Amplify.configure(awsconfig)

const OptinBrandingCourse = ({ url }) => {
  const [status, setStatus] = useState("loading")

  const email = getUriParameterByName("email", url)

  useEffect(() => {
    const subscribeToCourse = () =>
      API.get("optinbrandingcourse", "/items", {
        queryStringParameters: { email },
      }).then(res => {
        if (res === "success") setStatus("ok")
        else if (res.status === 500 && res.error === "no_subscriber")
          setStatus("not_found")
        else setStatus("error")
      })

    subscribeToCourse()
  }, [email])

  // retry option

  const renderInner = () => {
    if (status === "ok") {
      return (
        <Text standalone italic>
          <p>
            We've just registered <b>{email}</b> to our free email course. You
            will receive the first message within minutes.
          </p>
        </Text>
      )
    } else if (status === "not_found") {
      return (
        <Text standalone italic>
          <p>
            We couldn't find <b>{email}</b> in our database. Please contact us
            at <a href="mailto:team@logology.co">team@logology.co</a> to resolve
            this.
          </p>
        </Text>
      )
    } else if (status === "error") {
      return (
        <Text standalone italic>
          <p>
            There was an error processing your request. Please refresh the page
            or contact us at{" "}
            <a href="mailto:team@logology.co">team@logology.co</a> to resolve
            this.
          </p>
        </Text>
      )
    }
    return <Spinner standalone></Spinner>
  }

  return (
    <>
      <SEO title="Optin to branding course" />

      <GridBox colSpans={[[2, 5], [2, 6], [2, 5]]} withBgContainer>
        <Title standalone level={2}>
          Get your free branding course
        </Title>
      </GridBox>

      <GridBox colSpans={[[2, 6], [3, 6], [3, 5]]} withBgContainer>
        {renderInner()}
      </GridBox>
    </>
  )
}

export default connect(
  null,
  {}
)(OptinBrandingCourse)
